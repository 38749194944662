<template>
  <footer class="footer">
    <Alert
      :alert-visible="showAlert"
      :alert-data="alertData"
      @closeModal="isAlertClosed"
    />
    <div class="main__footer">
      <!-- ############################## Subscribe to The mailing List Section ########################################### -->
      <v-form ref="formRef" class="email-box" lazy-validation>
        <v-row class="email">
          <v-col cols="12" sm="2" md="1" class="email__icon">
            <img src="/assets/images/footer/mail.svg" />
          </v-col>
          <v-col cols="12" sm="4" md="5">
            <p>
              اشترك في القائمة البريدية ليصلك كل ماهو جديد من مواد و إصدارات
              وغيرها من المقالات
            </p>
          </v-col>
          <!-- <v-col v-if="mdAndUp" md="2" /> -->
          <v-col cols="8" sm="4" md="5">
            <v-text-field
              v-model="email"
              required
              solo
              rounded
              class="email__input"
              placeholder="البريد الإلكتروني"
              type="email"
              :rules="emailRules"
              validate-on-blur
              @blur="() => formRef.resetValidation()"
            />
          </v-col>
          <v-col cols="4" sm="2" md="1">
            <v-btn @click="addToMailingList" class="subscribe-btn">
              اشترك
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!-- ############################## LOGO Section ########################################### -->
      <v-row class="payment-ways">
        <v-col cols="12" md="3" class="contactus">
          <h4 class="section-title">التواصل و الدعم :</h4>
          <div>
            <p>تواصل معنا :</p>
            <span>
              <a href="#">
                <img
                  @click="whatsApp(support.support_whatsapp)"
                  src="~/assets/images/social-icons/whatsapp.svg"
                />
              </a>
              <a href="mailto:support@sumaya369.net">
                <img src="~/assets/images/social-icons/email-d.svg"
              /></a>
            </span>
          </div>
          <div>
            <p>الدعم الفني :</p>
            <span>
              <nuxt-link to="/FAQ">
                <img src="~/assets/images/social-icons/support.svg" />
              </nuxt-link>
            </span>
          </div>
          <div>
            <p>للإقتراحات :</p>
            <span style="direction: ltr">{{ support.support_phone }}</span>
          </div>
        </v-col>
        <!-- ############################## Social Links Section ########################################### -->
        <v-col cols="12" md="4" class="social-links">
          <h4 class="section-title">التواصل الاجتماعي :</h4>
          <div>
            <p>تابع الدكتورة سمية على :</p>
            <span>
              <a
                :href="`https://twitter.com/${drSumaya.sumaya_twitter}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/twitter.svg" />
              </a>
              <a
                :href="`https://www.snapchat.com/add/${drSumaya.sumaya_snapchat}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/Snapchat.svg" />
              </a>
              <a
                :href="`https://www.instagram.com/${drSumaya.sumaya_instagram}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/instagram.svg" />
              </a>
              <a
                :href="`https://www.tiktok.com/@${drSumaya.sumaya_tiktok}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/tiktok.svg" />
              </a>
              <a
                :href="`https://www.youtube.com/${drSumaya.sumaya_youtube}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/youtube.svg" />
              </a>
              <a
                :href="`https://podcasts.apple.com/us/podcast/${drSumaya.sumaya_podcast}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/podcast.svg" />
              </a>
            </span>
          </div>
          <!-- <br> -->
          <div>
            <p>تابعنا :</p>
            <span>
              <a
                :href="`https://twitter.com/${teamAccounts.team_twitter}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/twitter.svg" />
              </a>

              <a
                :href="`https://www.instagram.com/${teamAccounts.team_instagram}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/instagram.svg" />
              </a>
              <a
                :href="`https://www.pinterest.com/${teamAccounts.team_pinterest}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/pinterest.svg" />
              </a>
              <a
                :href="`https://telegram.me/${teamAccounts.team_telegram}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/telegram.svg" />
              </a>
              <a
                :href="`https://www.facebook.com/${teamAccounts.team_facebook}`"
                target="_blank"
                rel=""
              >
                <img src="~/assets/images/social-icons/facebook.svg" />
              </a>
            </span>
          </div>
        </v-col>
        <!-- ############################## Payments way Section ########################################### -->
        <v-col cols="12" md="5" class="pay-ways">
          <div class="vat">
            <a
              v-if="!smAndDown"
              href="https://drive.google.com/u/0/uc?id=1cOw45xFOzYn9Hai7iU7Bw3r9HB465pth&export=download"
              target="_self"
            >
              <img src="~/assets/images/footer/vat.svg"
            /></a>
          </div>
          <div>
            <div class="pay_icons">
              <span v-if="mdAndDown" class="ml-2 pay-small-label"
                >طرق دفع آمنة</span
              >

              <v-row
                class="pay-icons-row"
                :class="{ 'my-2 center-row': mdAndDown }"
              >
                <v-col v-if="lgAndUp" cols="3" sm="2" md="3" lg="3">
                  <span class="ml-2 pay-large-label">طرق دفع آمنة</span>
                </v-col>
                <div>
                  <img
                    src="~/assets/images/footer/master-card.svg"
                    title="Master Card"
                    class="payment-logos"
                  />
                </div>
                <div>
                  <img
                    src="~/assets/images/footer/visa.png"
                    title="VISA"
                    class="payment-logos"
                  />
                </div>
                <!-- <div>
                  <img
                    src="~/assets/images/footer/apple-pay.svg"
                    title="Apple Pay"
                    class="payment-logos"
                  >
                </div> -->
                <!-- <div>
                  <img
                    src="~/assets/images/footer/stc.svg"
                    title="STC Pay"
                    class="payment-logos"
                  >
                </div> -->
                <div>
                  <img
                    src="~/assets/images/footer/mada.svg"
                    title="Mada"
                    class="payment-logos"
                  />
                </div>
                <!-- <div>
                  <img
                    src="~/assets/images/checkout/spotii.svg"
                    alt="spotii"
                    class="payment-logos"
                  >
                </div> -->
                <div>
                  <img
                    src="~/assets/images/checkout/tamara-ar.svg"
                    alt="tamara"
                    class="payment-logos"
                  />
                </div>
                <div id="TabbyPromo">
                  <img
                    src="~/assets/images/footer/tabby-badge.svg"
                    alt="tabby"
                    class="payment-logos"
                  />
                </div>
              </v-row>
              <a
                v-if="smAndDown"
                href="https://drive.google.com/u/0/uc?id=1cOw45xFOzYn9Hai7iU7Bw3r9HB465pth&export=download"
                target="_self"
              >
                <img src="~/assets/images/footer/vat.svg"
              /></a>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- ############################## Privacy Policy Section ########################################### -->
      <v-row>
        <v-col class="privacy">
          <p class="copy-right">
            {{ `© ${currentDate}  Elyak cjsc. All rights reserved` }}
          </p>
          <div v-if="!xsOnly" class="bootfi-logo">
            <a href="https://bootfi.com" target="_blank">
              <img width="42" src="~/assets/images/bootfi.svg" />
            </a>
            <br />
            <p>© تم تطويره بواسطة شركة بوتفاي لتقنية المعلومات</p>
          </div>
          <div class="footer-logo">
            <img src="~/assets/images/footer/footer-logo.svg" />

            <br />
            <Nuxt-link to="/pages/terms-and-conditions-with-privacy-policy">
              الشروط و الأحكام
            </Nuxt-link>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="bootfi-logo" v-if="xsOnly">
          <div>
            <a href="https://bootfi.com" target="_blank">
              <img width="42" src="~/assets/images/bootfi.svg" />
            </a>
            <br />
            <p>© تم تطويره بواسطة شركة بوتفاي لتقنية المعلومات</p>
          </div>
        </v-col>
      </v-row>
    </div>
  </footer>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useDisplay } from "vuetify";

import Alert from "~/components/shared/Alert.vue";
import { useHomeStore } from "~/stores/HomeStore";
const { mdAndDown, xsOnly, smAndDown, lgAndUp } = useDisplay();

const homeStore = useHomeStore();
// Reactive state variables
const email = ref("");
const drSumaya = ref({});
const teamAccounts = ref({});
const support = ref({});
const showAlert = ref(false);
const alertData = ref({});
const currentDate = new Date().getFullYear();
const formRef = ref(null);

const emailRules = [
  (v) => !!v || "الإيميل مطلوب",
  (v) =>
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v,
    ) || "الإيميل غير صحيح",
];

// Computed properties
const header = computed(() => homeStore.header.header);
const footer = computed(() => (homeStore.footer ? homeStore.footer : {}));
const socialMedia = computed(() =>
  footer.value ? footer.value["التواصل الاجتماعي"] : {},
);

// Methods
const addToMailingList = () => {
  const valid = formRef.value.validate();
  if (valid) {
    loading.value = true;
    const formData = new FormData();
    formData.append("email", email.value);
    homeStore.addToMailingList(formData).then((resData) => {
      setAlertData(resData);
      email.value = "";
    });
    loading.value = false;
  }
};

const whatsApp = (number) => {
  window.open(`https://wa.me/${number}`, "_blank");
};

const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};

const isAlertClosed = (payload) => {
  if (payload.value) {
    showAlert.value = false;
  }
};

// Lifecycle hooks
onMounted(() => {
  setTimeout(async () => {
    await homeStore.homeFooter().then(() => {
      if (socialMedia.value) {
        drSumaya.value = socialMedia.value["تابع الدكتورة سمية على"] || {};
        teamAccounts.value = socialMedia.value["تابعنا"] || {};
        support.value = footer.value ? footer.value["التواصل و الدعم"] : {};
      }
    });
  }, 500);
});
</script>

<style lang="scss" scoped>
.footer {
  padding: 0px;
  margin-top: 53px;
  background: #fff;
  .main__footer {
    background-color: #f9f7fa;
  }
  .section-title {
    font-size: 19px;
    font-weight: bold;
    color: #000;
    margin-bottom: 12px;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    color: #404041;
    margin-left: 8px;
  }
  .email-box {
    padding-left: 13%;
    padding-right: 13%;
    margin-top: -50px;
    margin-bottom: 15px;
    .email {
      align-content: center;
      padding: 30px;
      background-color: #f4eef6;
      border-radius: 25px;
      color: #000;
      display: flex;
      align-items: center;
      margin-top: -60px;
      .email__icon {
        display: grid;
        place-items: center;
        img {
          width: 100%;
        }
      }
      p {
        font-size: 16px;
        font-weight: bold;
      }
      .email__input {
        margin-bottom: -8px !important;
      }
      .subscribe-btn {
        border-radius: 25px;
        background-color: #87599d !important;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }
  }
  .contactus {
    div {
      display: flex;
      flex-flow: wrap;
      span {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        align-items: center;
        img {
          margin: 0px 5.5px;
        }
      }
    }
  }
  .payment-ways {
    padding: 0 50px;
    font-weight: bold;
    font-size: 16px;
    color: #404041;
    .social-links {
      div {
        display: flex;
        flex-flow: wrap;
        margin: 10px 0px;
        span {
          display: flex;
          flex-flow: wrap;
          a {
            display: flex;
            justify-content: center;
          }
          img {
            margin: 4px 5.5px;
          }
        }
      }
    }
  }
  .pay-ways {
    .vat {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 10px;
    }
    .pay_icons {
      display: flex;
      justify-content: left;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: wrap;
      }
      img {
        margin: 4px 4px 4px 0;
      }
    }
  }
  .sort-pay-ways {
    order: 3;
  }
  .privacy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    background-color: #fff;
    font-size: 13px;
    font-weight: bold;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    p,
    a {
      color: #404041 !important;
    }
    a {
      text-decoration: none;
      &:hover,
      &.nuxt-link-activ {
        color: #87599d;
      }
    }
    .footer-logo {
      width: max-content;
      text-align: center;
      img {
        width: 120px;
      }
    }
    .copy-right {
      direction: ltr;
    }
  }
}
.bootfi-logo {
  text-align: center;
  background-color: #fff;
  img {
    width: 42px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.86;
    color: #474646;
  }
}

.payment-logos {
  max-height: 25px;
  max-width: 45px;
  width: auto;
}
.pay-icons-row {
  display: flex;
  justify-content: left;
  & > div {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .col-lg-1 {
    flex: 0 0 12.333333%;
    max-width: 12.333333%;
  }
}
@media (max-width: 1264px) {
  .footer {
    .email-box {
      padding-left: 6%;
      padding-right: 6%;
    }
    .payment-ways {
      .pay-ways {
        display: block !important;
        text-align: center;
        p {
          margin-bottom: 10px;
        }
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: wrap;
        }
      }
    }
  }
  .pay-icons-row {
    & > div {
      margin: 0 2px;
    }
  }
}
@media (max-width: 991px) {
  .footer {
    .section-title {
      text-align: center;
    }
    .payment-ways {
      .social-links {
        div {
          text-align: center;
          display: block !important;
          p {
            width: auto !important;
            margin-bottom: 10px;
          }
          span {
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            padding: 0px 7%;
          }
        }
      }
    }
    .pay-ways {
      .vat {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0;
      }
      .pay_icons {
        display: block;
        span {
          padding: 0 7%;
        }
      }
    }
    .contactus {
      & > div {
        justify-content: flex-start;
        padding: 0px 40% 0 0;
      }
    }
  }
}
@media (max-width: 600px) {
  .footer {
    .email-box {
      padding-left: 2%;
      padding-right: 2%;
      .email {
        padding: 20px 15px;
        .email__icon {
          img {
            width: auto;
          }
        }
        p {
          text-align: center;
        }
      }
    }
    .payment-ways {
      padding: 0 30px;
    }
    .contactus {
      & > div {
        justify-content: flex-start;
        padding: 0px 22% 0 0;
      }
    }
    .privacy {
      display: flex;
      flex-direction: column-reverse;
      padding: 0px;
      .copy-right {
        text-align: center;
        direction: ltr;
      }
      .logo {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .bootfi-logo {
      padding: 5px 0 15px;
    }
  }
  .row {
    margin: 0px !important;
  }
}
@media (max-width: 360px) {
  .footer {
    .email {
      & > .col-9,
      & > .col-3 {
        margin-right: -20px;
      }
    }
    .contactus {
      & > div {
        padding: 0px 16% 0 0;
      }
    }
    .payment-ways {
      .social-links {
        div {
          span {
            padding: 0px 5%;
          }
        }
      }
    }
    .pay-ways {
      .pay_icons {
        span {
          padding: 0;
        }
      }
    }
    .privacy {
      .logo {
        img {
          width: 110px;
        }
      }
    }
  }
  .pay-icons-row {
    & > div {
      margin: 0 10px;
    }
  }
}
.pay-icons-row {
  & > div {
    justify-content: flex-end;
  }
}
.center-row {
  justify-content: center;
}
</style>
