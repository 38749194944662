<template>
  <div>
    <div>
      <LoginModal
        :dialog-visible="showLogin"
        @closeModal="isLoginDialogClosed"
      />

      <div class="header" :class="{ 'fixed-top': windowScrollY > 10 }">
        <!-- ############################## HomePage Links Section ########################################### -->
        <div class="links">
          <ul>
            <li class="menu">
              <Menu />
            </li>
            <li class="logo" @click="goHome()">
              <img
                v-if="xsOnly && $route.path === '/' && windowScrollY < 10"
                src="~/assets/images/header/logo-w.svg"
              />
              <img
                v-else-if="xsOnly"
                src="~/assets/images/header/small-logo.svg"
              />
              <img v-else src="~/assets/images/header/logo.svg" />
            </li>
            <!-- <li>
              <nuxt-link to="/"> الرئيسية </nuxt-link>
            </li> -->
            <li>
              <nuxt-link to="/pages/about"> عن د.سمية </nuxt-link>
            </li>
            <li>
              <v-menu
                offset-y
                transition="slide-y-transition"
                min-width="102px"
                class="courses"
              >
                <template v-slot:activator="{ props }">
                  <span rounded v-bind="props" class="courses-btn">
                    <a> المــــواد</a>
                    <img
                      v-if="props"
                      src="~/assets/images/header/menu-down.svg"
                      class="icon"
                    />
                    <img
                      v-else
                      src="~/assets/images/header/menu-up.svg"
                      class="icon"
                    />
                  </span>
                </template>
                <div class="courses-menu">
                  <nuxt-link to="/materials"> كافة المواد </nuxt-link>
                  <nuxt-link
                    :to="{
                      path: '/materials',
                      query: { all_reduced_courses: true },
                    }"
                  >
                    المواد المخفضة
                  </nuxt-link>
                </div>
              </v-menu>
              <!-- <a v-if="inHomePage" href="/#courses"> المـواد </a>
              <nuxt-link v-else to="/materials"> المـواد </nuxt-link> -->
            </li>
            <li>
              <v-menu
                offset-y
                transition="slide-y-transition"
                min-width="102px"
                class="courses"
              >
                <template v-slot:activator="{ props }" class="courses-btn">
                  <span rounded v-bind="props">
                    <a class="menu-title pl-1"> الإصدارات و المقالات</a>
                    <img
                      v-if="props"
                      src="~/assets/images/header/menu-down.svg"
                      class="icon"
                    />
                    <img
                      v-else
                      src="~/assets/images/header/menu-up.svg"
                      class="icon"
                    />
                  </span>
                </template>
                <div class="courses-menu">
                  <a v-if="inHomePage" href="/#publications"> الإصدارات </a>
                  <nuxt-link v-else to="/publications"> الإصدارات </nuxt-link>
                  <a v-if="inHomePage" href="/#articles"> المقالات </a>
                  <nuxt-link v-else to="/articles"> المقالات </nuxt-link>
                </div>
              </v-menu>
              <!-- <a v-if="inHomePage" href="/#courses"> المـواد </a>
              <nuxt-link v-else to="/materials"> المـواد </nuxt-link> -->
            </li>
            <li>
              <nuxt-link to="/events">أحداث الشهر</nuxt-link>
            </li>
            <li v-if="isLogin">
              <nuxt-link to="/pages/awareness-dictionary">
                قاموس الوعي
              </nuxt-link>
            </li>
            <li v-else>
              <span @click="showLogin = true" class="awareness-dictionary">
                قاموس الوعي
              </span>
            </li>
            <!-- <li>
              <nuxt-link to="/soon"> الخدمة المجتمعية </nuxt-link>
            </li> -->
            <li>
              <v-menu
                offset-y
                transition="slide-y-transition"
                min-width="102px"
                class="courses"
              >
                <template v-slot:activator="{ props }">
                  <span rounded v-bind="props" class="courses-btn">
                    <a>المزيــــــــــد</a>
                    <img
                      v-if="true"
                      src="~/assets/images/header/menu-down.svg"
                      class="icon"
                    />
                    <img
                      v-else
                      src="~/assets/images/header/menu-up.svg"
                      class="icon"
                    />
                  </span>
                </template>
                <div class="courses-menu">
                  <div class="list">
                    <ul>
                      <li>
                        <a href="https://Lax.elyak.com" target="_blank">
                          تطبيق لاكس
                        </a>
                      </li>
                      <li v-show="isLogin">
                        <a href="https://book.sumaya369.net/" target="_blank">
                          استشارات توجيهية
                        </a>
                      </li>
                      <li>
                        <nuxt-link to="/FAQ"> الأسئلة الشائعة </nuxt-link>
                      </li>
                      <li v-for="page in pagesList" :key="page.key">
                        <nuxt-link
                          v-if="!page.logged_in_only"
                          :to="`/pages/${page.key}`"
                        >
                          {{ page.title }}
                        </nuxt-link>
                        <span
                          v-else-if="page.logged_in_only && !isLogin"
                          @click="showLogin = true"
                          class="page-link"
                        >
                          {{ page.title }}
                        </span>
                        <nuxt-link
                          v-else-if="page.logged_in_only && isLogin"
                          :to="`/pages/${page.key}`"
                        >
                          {{ page.title }}
                        </nuxt-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-menu>
            </li>
          </ul>
        </div>
        <!-- Cart Component  -->
        <Cart
          class="cart"
          :class="{ 'd-flex justify-end': xsOnly }"
        />
      </div>
    </div>
    <!-- Hero Component  -->
    <hero v-if="!errorPage" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import Cart from "~/components/header/Cart";
import Hero from "~/components/header/Hero.vue";
import Menu from "~/components/header/Menu";
import LoginModal from "~/components/auth/LoginModal.vue";
import { useAuthStore } from "~/stores/AuthStore";
import { useHomeStore } from "~/stores/HomeStore";
import { useDisplay } from "vuetify";

// import { useWindowScroll } from '~/composables/window-scroll'; // Custom composable for window scroll

const props = defineProps({
  errorPage: {
    type: Boolean,
    default: false,
  },
});

const { xsOnly } = useDisplay();

const homeStore = useHomeStore();
const authStore = useAuthStore();

// Reactive state variables
const showLogin = ref(false);
const display = ref(true);
const pagesList = ref([]);
const windowScrollY = ref(0);

// Vue Router
const route = useRoute();
const router = useRouter();

// Computed properties
const inHomePage = computed(() => route.path === "/");
const user = computed(() => authStore.auth.user);
const isLogin = computed(() => authStore.auth.isLoggedIn);
const header = computed(() => homeStore.header);

// Methods
const goHome = () => {
  router.push({ name: "index" });
};

const sinup = () => {
  navigateTo('/auth/signup')
};

const isLoginDialogClosed = (payload) => {
  if (payload.value) {
    showLogin.value = false;
    if (payload.showSignup) {
      sinup();
    }
  }
};

const updateScroll = () => {
  windowScrollY.value = window.scrollY;
};

// Lifecycle hooks
onMounted(async () => {
  window.addEventListener("scroll", updateScroll);

  if (homeStore.query.promo) {
    await homeStore.homeHeader(homeStore.query.promo);
  } else {
    await homeStore.homeHeader();
  }
  router.push({ path: route.path, query: {} });

  // Assuming you have a method to set RTL in your store
  // homeStore.setVuetifyRtl(true);

  if (header.value && header.value.header) {
    header.value.header.pages.forEach((element) => {
      pagesList.value.push(element);
    });
  }
});
</script>

<style lang="scss">
.header {
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  background-color: transparent;
  padding: 1.6%;
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  .links {
    width: 100%;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      font-weight: bold;
      li {
        margin: 0 1.5%;
        a {
          text-decoration: none;
          color: #000;
          &:hover,
          &.nuxt-link-activ {
            color: #87599d;
          }
        }
      }
    }
  }
  .cart {
    width: 31%;
  }
  .menu {
    display: none;
  }
}
.fixed-top {
  background-color: #fff !important;
  box-shadow: 0 3px 66px 0 rgba(0, 0, 0, 0.16);
}
.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.courses-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  img {
    margin-right: 10px;
  }
}
.courses-menu {
  display: flex;
  flex-direction: column;
  background-color: #87599d;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 5px;
  .list {
    max-height: 150px;
    overflow: auto;
    margin: 10px 0 5px;
    cursor: pointer;
    scrollbar-color: #eec2ea #f6f5f7;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px transparent;
      background-color: #f6f5f7;
      border-radius: 12px;
      margin: 5px 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d6b1d2;
      outline: none;
      border-radius: 12px;
    }
  }
  .page-link,
  a {
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    line-height: 1.07;
    padding: 5px;
  }
  .page-link:hover,
  a:hover {
    color: #000;
  }
}
@media (max-width: 1600px) {
  .header {
    .links {
      li {
        margin: 0 1.2% !important;
      }
    }
  }
  .menu-title {
    font-size: 14px !important;
  }
}
@media (max-width: 1500px) {
  .header {
    .links {
      li {
        margin: 0 0.6% !important;
      }
    }
  }
}

@media (max-width: 1350px) {
  .header {
    padding: 1.6% 1.6% 2%;
    .links {
      width: 50%;
      li:not(.logo, .menu) {
        display: none;
      }
    }
    .cart {
      width: 50%;
    }
  }
  .menu {
    display: block !important;
    margin: 0 10px;
  }
}
@media (max-width: 600px) {
  .links {
    li {
      margin: 0 !important;
    }
  }
  .cart {
    margin-left: 0px;
  }
}
@media (max-width: 360px) {
  .header {
    padding: 1.6% 0;
  }
}
</style>
